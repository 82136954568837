<template>
  <v-container fluid class="wid-100">
    <template>
      <v-row justify="center" align="center">
        <v-col cols="12" >
          <v-data-table
          :headers="headers"
          :items="list"
          :items-per-page="5"
          disable-sort
          class="elevation-1"
        ></v-data-table>
        </v-col>
      </v-row>
    </template>
  </v-container>
  
</template>

<script>
export default {
  data() {
    return {
      list: [],
      headers: [
        {
          text: '네이버 아이디',
          align: 'start',
          sortable: false,
          value: 'naverid',
        },
        { text: '이름', value: 'navername' },
        { text: '등록일', value: 'reg_dt' }
      ],
    }
  },
  created() {
    this.getNaverList();
  },
  methods: {
    getNaverList() {
      this.$http.get("/admin/sub/getNaverList").then((res) => {
        console.log(res.data);
        this.list = res.data.naverList;
        
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    }
  }
}
</script>