<template>
  <v-container fluid class="wid-100">
    <template>
      <v-row justify="center" align="center">
        <v-col cols="12" >
          <v-data-table
          :headers="headers"
          :items="list"
          :items-per-page="5"
          disable-sort
          class="elevation-1"
        ></v-data-table>
        </v-col>
      </v-row>
    </template>
  </v-container>
  
</template>

<script>
export default {
  data() {
    return {
      list: [],
      headers: [
        {
          text: '인스타 아이디',
          align: 'start',
          sortable: false,
          value: 'inst_id',
        },
        { text: '신청자 이름', value: 'prop_name' },
        { text: '신청자 전화번호', value: 'prop_tel' },
        { text: '신청자 주소', value: 'prop_addr' },
        { text: '아이 이름', value: 'child_name' },
        { text: '아이 나이', value: 'child_age' },
        { text: '등록일', value: 'reg_dt' }
      ],
    }
  },
  created() {
    this.getInstaList();
  },
  methods: {
    getInstaList() {
      this.$http.get("/admin/sub/getInstaList").then((res) => {
        console.log(res.data);
        this.list = res.data.instaList;
        
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    }
  }
}
</script>